@font-face {
    font-family: 'Archivo-Regular';
    src: url('./Fonts/Archivo-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Archivo-Medium';
    src: url('./Fonts/Archivo-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Archivo-Bold';
    src: url('./Fonts/Archivo-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'FRL-Regular';
    src: url('./Fonts/FrankRuhlLibre-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'FRL-Medium';
    src: url('./Fonts/FrankRuhlLibre-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'FRL-Bold';
    src: url('./Fonts/FrankRuhlLibre-Bold.ttf') format('truetype');
}

.btn-secondary:focus, .btn-secondary.focus {
    background-color: #BF7247 !important;
    box-shadow: 0 0 0 0.2rem rgba(191, 114, 71, 0.5) !important;
}

.btn-secondary:active {
    border-color: #BF7247 !important;
}